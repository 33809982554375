import React, { useState ,useEffect,} from 'react';
import './Header.css'
import { Avatar, Col, Row, Input, Button, Popover,Badge } from 'antd'
import { UserOutlined,NotificationOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom' 
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useStateValue } from '../../Reducer/reducer'
import { actionTypes } from '../../Reducer/StateProvider'
import Notification from '../Notification/Notification';
import axios from 'axios';
import { BaseUrl } from '../Constants/Constants';



const Header = () => {
  const { Search } = Input;
  const [
    { IsAuthenticated },
    dispatch,
] = useStateValue();
  let url = window.location.href;
  let splittedUrl = url.split('/')[3];
  const [showNotification, setShowNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [courseNotificationCount, setCourseNotificationCount] = useState(0);

  const navigate = useNavigate()
  const Profile = localStorage.getItem("Profile");
  const ParsedProfile = JSON.parse( Profile)
  const userId = ParsedProfile.user_id;
  // console.log(JSON.parse( Profile),"headerprofile")
  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axios.get(`${BaseUrl}training-mapping/notificationscount/${userId}`);
        if (response.data.status === 'Ok' && response.data.statuscode === 200) {
          setNotificationCount(response.data.data.length);
        } else {
          setNotificationCount(0);
        }
      } catch (err) {
        console.error('Failed to fetch notification count:', err);
        setNotificationCount(0);
      }
    };

    fetchNotificationCount();
    const interval = setInterval(() => {
      fetchNotificationCount();
      console.log("going to fetch notification")
    }, 10000); // 30000 ms = 30 seconds
  
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axios.get(`${BaseUrl}course-mapping/notificationscount/${userId}`);
        if (response.data.status === 'Ok' && response.data.statuscode === 200) {
          setCourseNotificationCount(response.data.data.length);
        } else {
          setCourseNotificationCount(0);
        }
      } catch (err) {
        console.error('Failed to fetch notification count:', err);
        setNotificationCount(0);
      }
    };

    fetchNotificationCount();
    const interval = setInterval(() => {
      fetchNotificationCount();
      console.log("going to fetch notification")
    }, 10000); // 30000 ms = 30 seconds
  
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [userId]);

  const handleClick = (e) => {
    console.log(e)
    if (e == 'Dashboard') {
      navigate(`/`)
    }
    if (e == "Courses") {
      navigate(`/courses`)
    }
    if (e == 'gradebook') {
      navigate(`/gradebook`)
    }
    if (e == 'Training Module') {
      navigate(`/training`)
    }
  }

  const handleNotificationClick = () => {
    setShowNotification(!showNotification); // Toggle notification visibility
    if (!showNotification) {
      setNotificationCount(0); // Reset notification count
      setCourseNotificationCount(0)
  }
  };

  const handleSettings = () => {
    navigate(`/settings`)
  }
  const handleLogout = () => {
    googleLogout();
    navigate(`login`)
    localStorage.setItem("IsAuthenticated", "false");
    dispatch({
      type: actionTypes.SET_ISAUTHENTICATED,
      IsAuthenticated: "false"
    })
    
  }
  const ProfileContent = (
    <div>
      <p className='Contents'
       onClick={handleSettings}
       >
        Settings
      </p>
      <p className='Contents' onClick={handleLogout}>
        Logout
      </p>
    </div>
  )
  const ProfileTitle = (
    <>
      <Row>
        <Col span={4}>
          <Avatar className='headingAvatar' size={35} icon={<UserOutlined />} />
        </Col>
        <Col offset={2} span={16}>
          <p className='Username'>{Profile ? ParsedProfile.name || ParsedProfile.username : "username"} </p>
          <p className='Email'> {Profile ? ParsedProfile.email : "username@valuehealthsol.com"}</p>
        </Col>
      </Row>
    </>
  )

  return (
    <>
      {splittedUrl === 'login' || splittedUrl === 'signup' || splittedUrl === 'forgotpassword' ?
        <></>
        :
        <div className='headerDiv'>
          <Row>
            <Col span={6}>
              <img width={180} height={45}
              onClick={() => navigate('/')}
              className='headerLogoImg' src='/Logo/Training Nexus 2.jpg' alt='' />
            </Col>
            <Col span={18}>
              <Row className='pageHadingRow'>
                <Col
                style={{ display: 'flex' }}
                  onClick={() => handleClick('Dashboard')}
                  span={3}>
                  <h3 className={splittedUrl == "" ? "active" : ""} >Dashboard</h3>
                </Col>
                <Col
                  span={3}
                  style={{ display: 'flex' }}
                  onClick={() => handleClick('Courses')}>
                  <h3 className={splittedUrl == "courses" ? "active" : ""}>
                    Courses</h3>
                </Col>
                {/* <Col span={4} 
                style={{ display: 'flex' }}
                onClick={() => handleClick('Training Module')} 
                >
                  <h3 className={splittedUrl == "training" ? "active" : ""}>
                    Training Module
                    </h3>
                </Col> */}
                <Col span={3} 
                style={{ display: 'flex' }}
                onClick={() => handleClick('gradebook')} 
                >
                  <h3 className={splittedUrl == "gradebook" ? "active" : ""}>Gradebook</h3>
                  {/* <img style={{ marginTop: 7 }}
                   className='comingSoonImages'
                   src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} /> */}
                </Col>
              
                <Col offset={1} span={9}>
                  {/* <Search
                    className='headerSearch'
                    placeholder='Search any topic'
                    allowClear
                  /> */}
                </Col>
                <Col offset={0} span={2} >
                <Badge count={notificationCount+courseNotificationCount} style={{ marginTop: 10 }}>
            <NotificationOutlined className='Notification' onClick={handleNotificationClick}/>
            </Badge>

            {/* <img className='' src='/icons/coming-soon-1.png' alt='comingsoon' width={35} height={35} /> */}
                            
            </Col>
                <Col span={1}>
                  <Popover content={ProfileContent}trigger="click" title={ProfileTitle} >
                    <Avatar className='headingAvatar' size={35} icon={<UserOutlined />} />
                  </Popover>
                 
                </Col>

              </Row>
            </Col>
            
          </Row>
          {showNotification && <Notification userId={userId} onNotificationsViewed={() => setNotificationCount(0)} onClose={handleNotificationClick} />}
          {/* {showNotification && <Notification userId={userId} onNotificationsViewed={() => setNotificationCount(0)}/>}  */}
        </div>
     
     

      }
      
    </>

  )
}

export default Header