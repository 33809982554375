import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import SubComponent from './Components/SubComponents/SubComponent';
import Hrms from './Components/Hrms/Hrms';
import Login from './Components/Login/Login'
import Signup from './Components/Signup/SignUp'
import { Routes, Route, useNavigate } from 'react-router-dom';
// import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import Courses from './Components/Courses/Courses';
import Pages from './Components/Pages/Pages';
import CourseModule from './Components/Courses/CourseModule/CourseModule';
import Assessment from './Components/Courses/Assessment/Assessment';
import Settings from './Components/Settings/Settings';
import Portfolio from './Components/Portfolio/Portfolio';
import Mcq from './Components/Courses/Assessment/MCQ/Mcq';
import McqResult from './Components/Courses/Assessment/MCQ/McqResult';
import GradeBook from './Components/Gradebook/GradeBook';
import Dashboard from './Components/Dashboard/Dashboard';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginGoogle from './Components/Login/LoginGoogle';
import { useEffect, useState } from 'react';
import { useStateValue } from './Reducer/reducer';
import { notification } from 'antd';
import { actionTypes } from './Reducer/StateProvider';
import ImageAssessment from './Components/Courses/Assessment/ImageAssessment/ImageAssessment';
import ManualAssessment from './Components/Courses/Assessment/ManualAssessment/ManualAssessment';
import TrainingModule from './Components/Training/TrainingModule/TrainingModule';
import TrainingPage from './Components/Training/TrainingPage/TrainingPage';
import TrainingModulePage from './Components/Training/TrainingModulePage/TrainingModulePage';
import TrainingAssessment from './Components/Training/TrainingAssessment/TrainingAssessment';
import TrainingMcq from './Components/Training/TrainingAssessment/MCQ/TrainingMcq';
import TrainingImageAssessment from './Components/Training/TrainingAssessment/ImageAssessment/TrainingImageAssessment';
import TrainingManualAssessment from './Components/Training/TrainingAssessment/ManualAssessment/TrainingManualAssessment';
import TrainingMcqResult from './Components/Training/TrainingAssessment/MCQ/TrainingMcqResult';
import PasswordChange from './Components/PasswordChange/PasswordChange';
import { startSession,updateActive,endSession } from './Components/Login/sessionService';
import McqWithDesc from './Components/Courses/Assessment/MCQ/McqWithDesc';
import McqDescResult from './Components/Courses/Assessment/MCQ/McqDescResult';

function App() {
  const navigate = useNavigate()

  const [api, contextHolder] = notification.useNotification();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [
    { IsAuthenticated,UserID },
    dispatch,
  ] = useStateValue();

  const [ botView, setBotView ] = useState(false);

  let url = window.location.href;
  let splittedUrl = url.split('/')[3];


  let splitUrl = url.split('/');

  useEffect(() => {
    const assessmentExists = splitUrl.includes('assessment');
    const loginExists = splitUrl.includes('login');
    const signupExists = splitUrl.includes('signup');
    const forgotpasswordExists = splitUrl.includes('forgotpassword');
    const hrmsExists = splitUrl.includes('hrms');
    const PasswordChangeExists = splitUrl.includes('PasswordChange');

console.log(hrmsExists,"hrmsExists")
    if( !assessmentExists && !loginExists && !signupExists && !forgotpasswordExists && !hrmsExists && !PasswordChangeExists ){
      setBotView(true);
    } else{
      setBotView(false);
    } 
  }, [url])
  // console.log(botView, 'assessmentExists')



  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  useEffect(() => {
    // Your code to handle user activity
    const handleUserActivity = () => {
      setLastActivity(Date.now());
    };

    // Attach event listeners or use other mechanisms to track user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  const INACTIVITY_TIMEOUT = 60 * 60 * 1000; //  60 minutes in milliseconds

  useEffect(() => {
    const checkInactivity = () => {
      const currentTime = Date.now();
      if ( (currentTime - lastActivity) > INACTIVITY_TIMEOUT) {
        // openNotificationWithIconError('User inactive. Logging out...')
        // console.log('User inactive. Logging out...');
        localStorage.setItem("IsAuthenticated", "false");
        dispatch({
          type: actionTypes.SET_ISAUTHENTICATED,
          IsAuthenticated: "false"
        })
        setTimeout(() => {          
          if(splittedUrl !== 'login' && splittedUrl !== 'signup' && splittedUrl !== 'forgotpassword' && splittedUrl !== 'hrms'){
            navigate('/login');
            endSession(UserID);
          }
        }, 500)
        // You may redirect to a logout page or dispatch a logout action.
      } else{
        // localStorage.setItem("IsAuthenticated", "false");
      }
    };

    // Set up a timer to check inactivity at regular intervals
    const inactivityTimer = setInterval(checkInactivity, 1000); // Check every second

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(inactivityTimer);
      
    };
  }, [lastActivity]);

  useEffect(() => {
    if (IsAuthenticated == "false" || IsAuthenticated == null) {
      // openNotificationWithIconError('Please Login to continue');
      setTimeout(() => {
        if(splittedUrl !== 'login' && splittedUrl !== 'signup' && splittedUrl !== 'forgotpassword' && splittedUrl !== 'hrms'){
          navigate('/login')
        }        
      }, 500)
    }
  }, [])


  // useEffect(() => {
  //   const startAndUpdateSession = async () => {
      
  //     const interval = setInterval(() => {
  //       updateActive(UserID);
  //     }, 60000); // Update every minute

  //     return () => clearInterval(interval);
  //   };

  //   startAndUpdateSession();

   
  // }, [UserID]);


  return (
    <GoogleOAuthProvider clientId="6062148310-fmhvrd07ghkar0npoqr9ipegejfaf9di.apps.googleusercontent.com">
      <div className="app-container">
        {contextHolder}
        {
          splittedUrl === 'login' || splittedUrl === 'signup' || splittedUrl === 'forgotpassword' || splittedUrl === 'hrms' || splittedUrl === 'PasswordChange' ?
            <></>
            :
            <Header />
        }
        <div className='flex-container'>
          <div className='main-container'>
            <Routes>
              <Route exact path="/hrms/:id" element={<Hrms />}> </Route>
              <Route exact path="/login" element={<Login />}> </Route>
              <Route exact path='/PasswordChange' element={<PasswordChange />}></Route>
              {/* <Route exact path="/login" element={<LoginGoogle/>}> </Route> */}
              <Route exact path="/signup" element={<Signup />}></Route>
              <Route exact path="/courses" element={<Courses />}></Route>
              <Route exact path="/" element={<Dashboard />}></Route>
              <Route exact path="/courses/:id/pages/:moduleId" element={<Pages />}></Route>
              <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
              {/* <Route exact path='/' element={<SubComponent />}></Route> */}
              <Route exact path='/settings' element={<Settings />}></Route>
              <Route exact path='/gradebook' element={<GradeBook />}></Route>
              <Route exact path='/training' element={<TrainingModule />}></Route>
              <Route exact path='/training/:id/:courseId' element={<TrainingPage />} />
              <Route exact path="/training/:id/:courseId/pages/:moduleId" element={<TrainingModulePage />}></Route>
              <Route exact path='/training/:id/:courseId/assessment/:assessmentId' element={<TrainingAssessment />} />
              <Route exact path='/training/:id/:courseId/assessment/:assessmentId/mcq' element={<TrainingMcq />} />
              <Route exact path='/training/:id/:courseId/assessment/:assessmentId/image' element={<TrainingImageAssessment />} />
              <Route exact path='/training/:id/:courseId/assessment/:assessmentId/manual' element={<TrainingManualAssessment />} />
              <Route exact path='/training/:id/:courseId/assessment/:assessmentId/mcq/result' element={<TrainingMcqResult />} />
              <Route exact path='/portfolio' element={<Portfolio />}></Route>
              <Route exact path='/courses/:id' element={<CourseModule />} />
              <Route exact path='/courses/:id/assessment/:assessmentId' element={<Assessment />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/mcq' element={<Mcq />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/mcqwithdesc' element={<McqWithDesc />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/image' element={<ImageAssessment />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/manual' element={<ManualAssessment />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/mcq/result' element={<McqResult />} />
              <Route exact path='/courses/:id/assessment/:assessmentId/mcqwithdesc/result' element={<McqDescResult />} />
              
            </Routes>
          </div>
          {  
          botView &&
            <a href="https://uatprecium.widget.healthitplatform.com/0a05de51-acc3-47a5-9835-bacedcb79da4" target="_blank" rel="noopener noreferrer" style={{ borderRadius : 25 }} >
          <img src='/bot-img/P-Logo.png' alt='image' className='botImageClass' />
          </a>
          }
         
        </div>

        {/* <div className='flex-container'>
        <SubComponent />
      </div> */}
        {
          splittedUrl === 'login' || splittedUrl === 'signup' || splittedUrl === 'forgotpassword' || splittedUrl === 'hrms'  || splittedUrl === 'PasswordChange' ?
            <></>
            :
            <Footer />
        }
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
