import React, { useEffect, useState } from 'react'
import './GradeBook.css'
import BarChart from '../Charts/BarChart';
import Marquee from 'react-fast-marquee';
import { useStateValue } from '../../Reducer/reducer';
import { getTotalTime, getAllInprogressCourses, getAllCompletedCourses, getAllInCompletesCourses } from '../Login/sessionService';
import { CaretRightOutlined } from '@ant-design/icons';
import { Alert, Row, Col, Card, Button, Progress, Collapse, theme, Modal } from 'antd';
import DailyLoginTimeChart from '../Charts/DailyLoginTimeChart';
import axios from 'axios';
import { AssesmentUrl } from '../Constants/Constants';

const GradeBook = () => {
    const { token } = theme.useToken();
    const [TotalTime, setTotalTime] = useState('')
    const [CompletedCourses, setCompletedCourses] = useState('')
    const [inCompletesCourses, setInCompletesCourses] = useState('')
    const [inProgressCourses, setinProgressCourses] = useState('')
    const [Data, setData] = useState([])
    const [
        { IsAuthenticated, UserID },
        dispatch,
    ] = useStateValue();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    const data = [
        { type: 'You', marks: 38 },
        { type: 'Median', marks: 52 },
        { type: 'Max', marks: 61 },
    ];

    useEffect(() => {
        (async () => {
            const time = await getTotalTime(UserID);
            const CompletedCourses = await getAllCompletedCourses(UserID)
            const inprogressCourses = await getAllInprogressCourses(UserID)
            const InCompletesCourses = await getAllInCompletesCourses(UserID)

            setCompletedCourses(CompletedCourses)
            setInCompletesCourses(InCompletesCourses)
            console.log(CompletedCourses, "CompletedCourses")
            setinProgressCourses(inprogressCourses)
            setTotalTime(time);
        })();
    }, [UserID]);

    useEffect(() => {
        let body = {
            user_id: UserID
        }
        axios
            .post(`${AssesmentUrl}get_user_report`, body)
            .then((res) => {
                console.log(res)
                setData(res.data.user_score_report)
                console.log(res.data.user_score_report[0].assessments, "dataaa")
                let arr = []
                for (let index = 0; index < res.data.user_score_report.length; index++) {
                    let i = res.data.user_score_report[index]
                    let obj = {
                        key: index + 1,
                        label: <div> <p className='CollapseH1'> Completed {i.AssessmentName}</p>
                            <h4 style={{ margin: 0 }}>{i.CourseName}</h4>
                            {/* <p style={{margin:0}}>-</p> */}
                        </div>,
                        children: <>
                            <Card style={{ width: 400 }} >
                                <h1>
                                    You vs your Batch
                                </h1>
                                <BarChart data={i.assessments} />
                            </Card>
                        </>,
                        style: panelStyle,
                        // extra: 

                    }
                    arr.push(obj)
                }
                setData(arr)
                console.log(arr, "array")
            })
            .catch((err) => {
                console.log(err)
            })
    }, [UserID])


    return (
        <div className='GradeBook-main'>
            <Modal title="Daily Average Usage" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <DailyLoginTimeChart userId={UserID} />
            </Modal>
            <h1>
                Gradebook
            </h1>
            <Alert
                banner
                message={
                    <Marquee pauseOnHover gradient={false}>
                        Any changes in the assesment grades will be updated in the Gradebook after 24 hours.
                    </Marquee>
                }
            />
            <Row>
                <Col span={4} >
                    <Card className='GradeCard' title="Your Courses" bordered={false} style={{ width: 250, height: 230 }}>
                        <ul className="custom-list">
                            <li>
                                In Progress - {inProgressCourses}
                            </li>
                            <li>
                                Completed - {CompletedCourses}
                            </li>
                            <li>
                                Incomplete - {inCompletesCourses}
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col span={6} offset={3}>
                    <Card className='GradeCard'
                        title={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src='/icons/coming-soon-1.png'
                                    alt='coming soon'
                                    width={25}
                                    height={25}
                                    style={{ marginRight: 8 }}
                                />
                                <span>Leaderboard</span>
                            </div>
                        }
                        bordered={false}
                        style={{ width: 300, height: 230 }}
                    >
                        <Row>

                            <Col span={16}>
                                <p className='marks-p'>
                                    Your Points
                                </p>
                                <h3>
                                    -
                                </h3>
                            </Col>
                            <Col span={8}>
                                <p className='marks-p'>
                                    Total Time
                                </p>
                                <h3>
                                    -
                                </h3>
                            </Col>
                            <Button className='linkButton' type='link'>
                                View Leaderboard
                            </Button>
                        </Row>

                    </Card>
                </Col>
                <Col span={4} offset={2}>
                    <Card
                        className='GradeCard'
                        bordered={false}
                        style={{ width: 300, height: 230 }}
                        title={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src='/icons/coming-soon-1.png'
                                    alt='coming soon'
                                    width={25}
                                    height={25}
                                    style={{ marginRight: 8 }}
                                />
                                <span>Timeline</span>
                            </div>
                        }
                    >
                        <Row>
                            <Col span={16}>
                                <p className='marks-p'>Today's Logged Time</p>
                                <h3>-</h3>
                            </Col>
                            <Button
                                style={{ cursor: 'not-allowed' }}
                                className='linkButton'
                                type='link'
                            // onClick={() => setIsModalOpen(true)}
                            >
                                View Timeline
                            </Button>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <div className='Collapse-Div'>

                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    style={{
                        background: token.colorBgContainer,
                    }}
                    items={Data}
                />


            </div>
        </div>
    )
}

export default GradeBook



